import { css } from '@emotion/react';
import { textCss } from '~/styles/textCss';
import { mediaQueries } from '~/styles/mediaQueries';

export const outerContainer = css`
  display: flex;
  width: 100%;
  padding: 0 16px;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
`;

export const container = css`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 1120px;
  width: 100%;
  padding: 48px 0;

  ${mediaQueries.mobile} {
    padding: 24px 0;
    gap: 24px;
  }
`;

export const logoTitle = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;

    ${mediaQueries.mobile} {
      gap: 8px;
    }
  `,
  logo: css`
    ${mediaQueries.mobile} {
      width: 140px;
      height: 30px;
    }
  `,
  title: css`
    ${textCss({ size: 'xl', weight: 'bold' })}

    ${mediaQueries.mobile} {
      ${textCss({ size: 's', weight: 'bold' })}
    }
  `,
};
